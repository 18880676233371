/* Global Styles */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(135deg, #f0f0f0, #d9d9d9);
  color: #333;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Main Container */
.maincontent {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100vh; /* Ensure minimum full viewport height */
  overflow: hidden;
}

/* Left Content */
.leftcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #ffa64d, #ff4d4d); /* Gradient with vibrant colors */
  padding: 20px;
  box-sizing: border-box;
  width: 100%; /* Ensure full width */
  max-width: 400px; /* Limit maximum width */
  min-height: 100vh; /* Ensure it takes full viewport height */
}

.leftcontent .profpic {
  padding: 10px;
  height: 150px;
  width: 150px;
  border: 5px solid #fff; /* White border around profile picture */
  border-radius: 50%; /* Circular profile picture */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for profile picture */
}


/* Additional Styling for Left Content */
.leftcontent h1 {
  font-size: 32px;
  color: #fff; /* Text color */
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4); /* Text shadow for better readability */
}


.taskimg {
  width: 80%;
  height: auto;
  max-width: 300px;
}

/* Right Content */
.rightcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  overflow-y: auto;
  background: linear-gradient(135deg, #66ccff, #cc99ff);
  box-sizing: border-box;
  width: calc(100% - 400px); /* Adjusted for right content width */
  min-height: 100vh; /* Ensure it takes full viewport height */
}

.rightcontent h1 {
  font-size: 28px;
  padding: 20px 0;
  color: #444;
}

/* Input Content */
.inputcontent {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
}

.inputcontent .form-control {
  flex: 1;
  height: 50px;
  border-radius: 25px;
  padding-left: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.inputcontent .btn-primary {
  width: 80px;
  height: 50px;
  border-radius: 25px;
  background-color: #ff6f61;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.inputcontent .btn-primary:hover {
  background-color: #ff8a75;
}

/* Task List */
.tasklist {
  width: 100%;
}

.taskcontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  box-sizing: border-box;
}

.taskcontent .form-check-input {
  margin-right: 15px;
}

.taskcontent span {
  flex: 1;
  font-size: 18px;
  color: #333;
}

.completed {
  text-decoration: line-through;
  opacity: 0.6;
}

.taskcontent .btn-warning,
.taskcontent .btn-danger {
  width: 50px;
  height: 30px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .maincontent {
    flex-direction: column; /* Stack columns on larger screens */
  }

  .leftcontent {
    max-width: 100%; /* Allow full width on smaller screens */
    margin-bottom: 20px; /* Add some space between sections */
  }

  .rightcontent {
    max-width: 100%; /* Allow full width on smaller screens */
    width: 100%; /* Ensure full width */
  }
}

@media (max-width: 768px) {
  .leftcontent {
    box-shadow: none; /* Remove box shadow on smaller screens */
    max-width: 100%; /* Allow full width on smaller screens */
  }

  .rightcontent {
    padding: 20px 10px; /* Adjust padding for smaller screens */
    width: 100%; /* Ensure full width */
  }
}

@media (max-width: 576px) {
  .inputcontent .btn-primary {
    width: auto;
    padding: 0 15px;
  }
}
