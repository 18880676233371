.signcontent {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(-45deg, #ff6f61, #de4dff, #4dffae, #61a7ff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.signdiv {
    width: 90%; /* Use a percentage or max-width for responsiveness */
    max-width: 500px; /* Limit maximum width */
    margin: 20px; /* Add margin for spacing */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    padding: 20px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), inset 0 0 10px rgba(255, 255, 255, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.signdiv:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3), inset 0 0 15px rgba(255, 255, 255, 0.5);
}

.signdiv img {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 100%; /* Ensure image fits within container */
    margin-bottom: 20px;
}


.signdiv img:hover {
    transform: scale(1.05); /* slight zoom on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* more pronounced shadow on hover */
}

.signin {
    float: right;
    max-width: 320px;
    display: flex;
    padding: 0.5rem 1.4rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.25);
    gap: 0.75rem;
    color: #c4d2dc;
    background-color: #19242b;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0, 0.87, 0.12, 1);
  }
  
  .signin:hover {
    transform: scale(1.025);
  }
  
  .signin:active {
    transform: scale(0.975);
  }
  
  button svg {
    height: 24px;
    width: auto;
  }
  

.google {
    height: 30px;
    width: 30px;
margin-top: 20px;
    margin-right: 10px; /* spacing between the icon and text */
}

